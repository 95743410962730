export const documentKindTypes = [
  {
    title: "Все",
    value: "",
  },
  {
    title: "Управленческие документы",
    value: "MANAGEMENT_DOCUMENTS",
  },
  {
    title: "Документы кредитных организаций",
    value: "CREDIT_DOCUMENTS_ORGANIZATIONS",
  },
  {
    title: "НТД",
    value: "NORMATIVE_TECHNICAL_DOCUMENTATIONS",
  },
];
