

































































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as VersionCatalogView } from "@monorepo/catalog/src/store/modules/versionCatalogView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { breadcrumbs } from "@monorepo/catalog/src/views/VersionCatalogView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/viewTitle";
import { IVersionElement } from "@monorepo/catalog/src/views/VersionCatalogView/types/versionElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useGetTableLibs from "@monorepo/catalog/src/views/VersionCatalogView/composables/useGetTableLibs";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import ConcatFooterButton from "@monorepo/uikit/src/components/tableViews/ConcatFooterButton.vue";
import RestoreFooterButton from "@monorepo/uikit/src/components/tableViews/RestoreFooterButton.vue";
import DeleteFooterButton from "@monorepo/uikit/src/components/tableViews/DeleteFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/catalog/src/views/VersionCatalogView/utils/convertApiItemToUi";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/catalog/src/views/VersionCatalogView/utils/defaultCurrentFilters";
import { elementStatuses } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/elementStatuses";
import { documentKindTypes } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/documentKindTypes";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/fields";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";

const SECTION_NAME = "versionCatalogView";
const pluralFormTitles = ["Версия", "Версии", "Версий"];

export default defineComponent({
  name: "VersionCatalogView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    AddElement,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    ExportFooterButton,
    ConcatFooterButton,
    RestoreFooterButton,
    DeleteFooterButton,
    OpenCardButton,
  },
  data() {
    return {
      breadcrumbs,
      ModalType,
      viewTitle,
      viewUniqKey,
      isOnceSelected: false,
      educationIds,
      pluralFormTitles,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FILTER_PARENT],
      isLoadingAddIds: {} as Record<string, boolean>,
      isLoadingSend: false,
      isLoadingImport: false,
      exportVersionId: null as number | null,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters("app", ["isExportFileLoading"]),
    ...mapGetters(SECTION_NAME, ["isOpenFilters", "cells", "isSelectAll", "infiniteId", "data", "totalLength", "selectedIds", "isTableLoading"]),
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        elementStatuses,
        documentKindTypes,
        sources: [],
      };
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_MODIFY);
    },
    isCanSendToNsi(): boolean {
      return isAuthorityExist(this.user, authorities.GAS_NSI_EXPORT);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapMutations(SECTION_NAME, ["refreshData", "setSelectedIds"]),
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "addOpenedId",
      "getExportData",
      "setIsOpenFilters",
      "refreshScroll",
      "addNewVersion",
      "sendVersionToNsi",
      "importCsvVersionFile",
    ]),
    ...mapActions("app", ["openNewWindow"]),
    async importCsvFile(file: File) {
      if (file) {
        this.isLoadingImport = true;
        const formData = new FormData();
        let name = file.name?.split(".")[0];
        formData.append("file", file);

        await this.importCsvVersionFile({
          name,
          file: formData,
        });

        this.isLoadingImport = false;
      }
    },
    getVersionCsv(id: number) {
      if (id) {
        this.exportVersionId = id;
        this.openNewWindow(getFullPath(`${QUERY_PATH.GET_VERSION_CSV}/${id}`));
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    async sendToNsi(event: IVersionElement) {
      this.isLoadingSend = true;
      await this.sendVersionToNsi({
        versionId: event.id,
        documentType: event.documentKindReferenceArea,
      });
      this.isLoadingSend = false;
    },
    async openModal(event: IVersionElement, mode: string) {
      let newData;
      if (mode === "new") {
        this.isLoadingAddIds[event.id] = true;
        newData = await this.addNewVersion(event.id);
        this.isLoadingAddIds[event.id] = false;
      }
      const versionId = mode === "new" ? newData?.id : event.id;
      if (versionId) {
        this.$router.push({
          path: "/dictionaries/doc-type",
          query: {
            type: event.documentKindReferenceArea,
            versionId,
            mode: mode === "new" ? "edit" : mode,
          },
        });
      }
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const type = ref(ModalType.EDIT);

    useInitTableStoreModule(root, SECTION_NAME, VersionCatalogView as Module<unknown, unknown>);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useGetTableLibs(root);
    return {
      filtersLoaded,
      showModal,
      type,
      sectionName,
    };
  },
});
