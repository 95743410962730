import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Справочник",
    tooltip: "Справочник",
    defaultWidth: 150,
    value: fields.TYPE,
    sortField: "documentKindReferenceArea",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Версия",
    tooltip: "Версия",
    defaultWidth: 120,
    value: fields.VERSION,
    isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата создания",
    tooltip: "Дата создания",
    defaultWidth: 150,
    value: fields.CREATE_DATE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус",
    defaultWidth: 180,
    value: fields.STATUS,
    sortField: "state",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Источник",
    tooltip: "Источник",
    defaultWidth: 180,
    value: fields.PARENT,
    sortField: "parentVersion",
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Комментарий",
    tooltip: "Комментарий",
    defaultWidth: 220,
    value: fields.COMMENT,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
