import { fields } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/fields";

export const filtersElements = [
  {
    value: fields.VERSION,
    title: "Версия",
    type: "text",
    width: 268,
  },
  {
    value: fields.FILTER_PARENT,
    title: "Источник",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "version",
    autocompleteActionName: "getAutocompleteByVersion",
    width: 285,
  },
  {
    value: fields.CREATE_DATE,
    dateValues: [fields.CREATE_DATE_FROM, fields.CREATE_DATE_TO],
    title: "Период создания",
    tooltip: "Интервал времени в пределах которого была создана версия",
    type: "datePeriod",
    width: 226,
  },
  {
    value: fields.STATE,
    title: "Статус",
    tooltip: "Выбор из следующих вариантов: Недействующий, Черновик, Действующий",
    type: "select",
    selectItemsField: "elementStatuses",
    width: 285,
  },
];
