var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-view__base-wrap"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isTableLoading && !_vm.showModal)?_c('TmpLogoOverlay',{staticClass:"fixed"}):_vm._e()],1),_c('div',{staticClass:"table-view__toggle-navigation-view",attrs:{"data-scroll-start-marker":""}}),_c('v-container',{staticClass:"table-view__breadcrumbs-wrap pb-0"},[_c('Breadcrumbs',{attrs:{"items":_vm.breadcrumbs}})],1),_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex align-center"},[(_vm.isTableLoading)?_c('TmpBlock',{attrs:{"height":"32"}}):_c('h1',{staticClass:"table-view__title"},[_vm._v(_vm._s(_vm.viewTitle))])],1)])],1)],1),_c('ScrollPanel',[_c('v-row',[_c('v-col',[_c('Filters',{key:_vm.filtersLoaded,attrs:{"sectionName":_vm.sectionName,"value":_vm.isOpenFilters,"filtersElements":_vm.filtersElements,"setCurrentFilters":_vm.setCurrentFilters,"autocompleteFiltersKeys":_vm.autocompleteFiltersKeys,"selectItemsObj":_vm.selectItemsObj,"chipsObj":{ value: 'documentKindReferenceArea', selectItemsField: 'documentKindTypes' }},on:{"change":_vm.setIsOpenFilters}})],1)],1),(_vm.isTableLoading)?_c('TmpBlock',{staticClass:"mt-9 mb-3",attrs:{"height":"20"}}):_c('TableSettings',{staticClass:"mt-6",attrs:{"loaded-count":_vm.data.length,"total-count":_vm.totalLength}},[[_c('div',{staticClass:"ml-4 d-flex flex-row"},[(_vm.isCanEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();}}},'div',attrs,false),on),[(!_vm.isLoadingImport)?_c('v-file-input',{staticClass:"d-inline-block pa-0 mt-0",attrs:{"accept":"text/csv","hide-input":"","truncate-length":"15","prepend-icon":"mdi-upload","loading":_vm.isLoadingImport},on:{"change":_vm.importCsvFile}}):_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","width":2,"size":18,"color":"grey"}})],1)]}}],null,false,3918276078)},[_c('div',[_vm._v("Импортировать из csv")])]):_vm._e(),_c('ToggleTableCellsContainer')],1)]],2),(_vm.isTableLoading)?_c('TmpBlock',{attrs:{"height":"48"}}):_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('TableHeader',{staticClass:"table-header_fixed",attrs:{"tableHeaders":_vm.headers,"activeCells":_vm.cells},on:{"setCells":_vm.setCells},scopedSlots:_vm._u([{key:"sortPanel",fn:function(ref){
var id = ref.id;
return [_c('SortPanelContainer',{attrs:{"id":id,"sectionName":_vm.sectionName}})]}}]),model:{value:(_vm.isSelectAllElement),callback:function ($$v) {_vm.isSelectAllElement=$$v},expression:"isSelectAllElement"}})],1)],1)],1),_c('div',{class:['scroll-panel__shadow', { active: _vm.isOpenFilters }]}),_c('v-container',{staticClass:"table-view"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 animation-container"},[_c('Table',{attrs:{"tableHeaders":_vm.tableHeaders,"viewUniqKey":_vm.viewUniqKey,"sectionName":_vm.sectionName,"convertItemCb":_vm.convertApiItemToUi,"statusFieldName":"status","isShowOpenCardBtn":false},on:{"openModal":function($event){return _vm.openModal($event, 'read')}},scopedSlots:_vm._u([{key:"additionalOpenCard",fn:function(ref){
var event = ref.event;
return [_c('div',{staticClass:"d-flex flex-row buttons-wrap"},[(event.state === 'ACTIVE' && _vm.isCanSendToNsi)?_c('OpenCardButton',{staticClass:"buttons-wrap__item",attrs:{"title":"Отправить в ГАС НСИ","is-capitalize":false,"is-loading":_vm.isLoadingSend},on:{"open":function($event){return _vm.sendToNsi(event)}}}):_vm._e(),(!['ACTIVE', 'INACTIVE'].includes(event.state) && _vm.isCanEdit)?_c('OpenCardButton',{staticClass:"buttons-wrap__item",attrs:{"title":"Редактировать","is-capitalize":false},on:{"open":function($event){return _vm.openModal(event, 'edit')}}}):_vm._e(),(_vm.isCanCreate)?_c('OpenCardButton',{staticClass:"buttons-wrap__item",attrs:{"title":"Создать на основе","is-capitalize":false,"is-loading":_vm.isLoadingAddIds[event.id]},on:{"open":function($event){return _vm.openModal(event, 'new')}}}):_vm._e(),_c('OpenCardButton',{staticClass:"buttons-wrap__item",attrs:{"title":"Открыть версию","is-capitalize":false},on:{"open":function($event){return _vm.openModal(event, 'read')}}}),_c('OpenCardButton',{staticClass:"buttons-wrap__item buttons-wrap__download-btn",attrs:{"is-loading":_vm.exportVersionId === event.id && _vm.isExportFileLoading,"isShowTitle":false,"icon":"mdi-download"},on:{"open":function($event){return _vm.getVersionCsv(event.id)}}})],1)]}}])})],1)],1),_c('InfiniteScroll',{attrs:{"get-event-list":_vm.getEventList,"infinite-id":_vm.infiniteId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }