import { headerCheckboxValue } from "@monorepo/utils/src/variables/headerCheckboxValue";

export interface IVersionFields {
  ID: string;
  CHECKBOX: string;
  NAME: string;
  CATALOG: string;
  TYPE: string;
  VERSION: string;
  CREATE_DATE: string;
  CREATE_DATE_FROM: string;
  CREATE_DATE_TO: string;
  STATUS: string;
  STATE: string;
  PARENT: string;
  COMMENT: string;
  FILTER_PARENT: string;
}

export const fields: IVersionFields = {
  CHECKBOX: headerCheckboxValue,
  ID: "id",
  NAME: "name",
  CATALOG: "documentKindReferenceArea",
  TYPE: "type",
  VERSION: "version",
  CREATE_DATE: "createAt",
  CREATE_DATE_FROM: "createAtFrom",
  CREATE_DATE_TO: "createAtTo",
  STATUS: "status",
  STATE: "state",
  PARENT: "parent",
  COMMENT: "comment",
  FILTER_PARENT: "sourceId",
};
