import { render, staticRenderFns } from "./VersionCatalogView.vue?vue&type=template&id=86f51394&scoped=true&"
import script from "./VersionCatalogView.vue?vue&type=script&lang=ts&"
export * from "./VersionCatalogView.vue?vue&type=script&lang=ts&"
import style0 from "./VersionCatalogView.vue?vue&type=style&index=0&id=86f51394&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86f51394",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VContainer,VFileInput,VProgressCircular,VRow,VTooltip})
